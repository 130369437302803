<script setup lang="ts">
import * as _ from 'lodash-es';
import { computed, onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { DateTimeSchema, MessageSchema, NumberSchema } from '@/locales';
import { useLayout } from '@/layout/composables/layout';
import { usePracticeStore, useRootStore, useUserStore, useUserPracticeStore } from '@/stores';
import { ResourceConfig } from '@repo/core/resources';
import { useRouter } from 'vue-router';
import { Practice } from '@/core/models';

// components

// locale
const { t } = useI18n<[MessageSchema, DateTimeSchema, NumberSchema]>();

// utils
const { onProfileSidebarToggle } = useLayout();

// stores
const userStore = useUserStore();
const rootStore = useRootStore();
const router = useRouter();
const practiceStore = usePracticeStore();
const userPracticeStore = useUserPracticeStore();

// state
const { currentUser } = storeToRefs(userStore);
const { currentPractice } = storeToRefs(practiceStore);

// data
const practices = ref<Practice[]>([]);

// computed
const profileTag = computed(() => {
  if (currentUser.value?.id) return _.toUpper(Array.from(currentUser.value.firstName)[0]);

  return '';
});

const topMenuTitle = computed(() => {
  return currentPractice.value.name;
});

const showProfileSidebar = () => {
  onProfileSidebarToggle();
};
const onConfigButtonClick = async () => {
  await router.push({ name: 'settings' });
};

const onPracticeChange = async () => {
  rootStore.setEntityId(undefined);
  rootStore.setPracticeId(currentPractice.value.id);
  await router.push({ name: 'entities' });
};

const onBackToEntitiesClick = async () => {
  rootStore.clearCurrentEntity();
  await router.push('entities');
};

const loadUserData = async () => {
  await userStore.fetchCurrentUser();
};

// watches
watch(
  () => currentUser.value?.id,
  async (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      await loadUserData();
    }
  },
  { immediate: true }
);

// hooks
onMounted(async () => {
  // remove fetch when I figured out how the store works
  currentUser.value = await userStore.fetchCurrentUser();
  practices.value = await userPracticeStore.fetchPractices();
});
</script>

<template>
  <div class="layout-topbar">
    <div class="topbar-start tw-h-20">
      <img
        v-if="currentPractice.logo"
        class="tw-h-20"
        :src="`data:image/*;base64,${currentPractice.logo}`"
        alt="Image"
      />
      <div class="text-900 text-xl font-semibold m-3">{{ topMenuTitle }}</div>
    </div>

    <div class="topbar-end">
      <ul class="topbar-menu">
        <li v-if="currentUser.practiceRole && currentUser.practiceRole >= 200" class="ml-3 mr-3">
          <Dropdown v-model="currentPractice" :options="practices" option-label="name" @change="onPracticeChange()" />
        </li>
        <li v-if="currentUser.practiceRole && currentUser.practiceRole == 200" class="ml-3">
          <Button
            v-tooltip.bottom="t('top_bar.settings')"
            icon="pi pi-cog"
            text
            rounded
            severity="secondary"
            @click="onConfigButtonClick"
          ></Button>
        </li>
        <li class="ml-3 mr-3">
          <Button
            v-tooltip.bottom="t('top_bar.home')"
            icon="pi pi-home"
            rounded
            text
            severity="secondary"
            @click="onBackToEntitiesClick"
          ></Button>
        </li>
        <li class="topbar-profile">
          <Button v-tooltip.bottom="t('top_bar.account')" type="button" class="p-link" @click="showProfileSidebar">
            <b alt="Profile">{{ profileTag }}</b>
          </Button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
