import { ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { useUserPracticeService } from '@/core/services';
import { Practice } from '@/core/models';

export const useUserPracticeStore = defineStore('userPractice', () => {
  const userPractice = useUserPracticeService();

  // actions
  const fetchPractices = async () => {
    const result = await userPractice.getPractices();
    return result;
  };

  const actions = {
    fetchPractices
  };

  return { ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserPracticeStore, import.meta.hot));
}
