<script setup lang="ts">
import * as _ from 'lodash-es';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import { useAuthStore } from '@repo/vue/stores';
import { useRootStore, useUserStore } from '@/stores';

const { layoutState } = useLayout();

//stores
const rootStore = useRootStore();
const authStore = useAuthStore();
const userStore = useUserStore();

// state
const { currentUser } = storeToRefs(userStore);
const { layoutConfig } = useLayout();

// data
const colorScheme = ref<string>(layoutConfig.colorScheme.value);

// methods
const onLogout = async () => {
  rootStore.setEntityId(undefined);
  rootStore.setPracticeId(undefined);
  await authStore.logout({
    post_logout_redirect_uri: window.location.origin
  });
};

const changeColorScheme = (colorScheme: any) => {
  const themeLink = document.getElementById('theme-link');
  if (!themeLink) return;
  const themeLinkHref = themeLink.getAttribute('href');

  if (!themeLinkHref) return;

  const currentColorScheme = 'theme-' + layoutConfig.colorScheme.value.toString();
  const newColorScheme = 'theme-' + colorScheme;
  const newHref = _.replace(themeLinkHref, currentColorScheme, newColorScheme);

  replaceLink(themeLink, newHref, () => {
    layoutConfig.colorScheme.value = colorScheme;
  });
};

const replaceLink = (linkElement: any, href: any, onComplete: any) => {
  if (!linkElement || !href) {
    return;
  }

  const id = linkElement.getAttribute('id');
  const cloneLinkElement = linkElement.cloneNode(true);

  cloneLinkElement.setAttribute('href', href);
  cloneLinkElement.setAttribute('id', id + '-clone');

  linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

  cloneLinkElement.addEventListener('load', () => {
    linkElement.remove();

    const element = document.getElementById(id); // re-check
    element && element.remove();

    cloneLinkElement.setAttribute('id', id);
    onComplete && onComplete();
  });
};
</script>

<template>
  <Sidebar
    v-model:visible="layoutState.profileSidebarVisible.value"
    position="right"
    class="layout-profile-sidebar w-full sm:w-25rem"
  >
    <div class="flex flex-column mx-auto md:mx-0">
      <span class="mb-2 font-semibold">Welcome</span>
      <span class="text-color-secondary font-medium mb-5">{{ currentUser.firstName }} {{ currentUser.lastName }}</span>

      <ul class="list-none m-0 p-0">
        <li>
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
          >
            <div class="tw-flex tw-flex-col tw-gap-5">
              <div class="tw-flex tw-flex-row">
                <span>
                  <i class="pi pi-user text-xl text-primary"></i>
                </span>
                <div class="ml-3">
                  <span class="mb-2 font-semibold">Profile</span>
                </div>
              </div>
              <div class="tw-flex tw-flex-row tw-justify-center tw-gap-3">
                <div class="field-radiobutton flex-1">
                  <RadioButton
                    v-model="colorScheme"
                    name="colorScheme"
                    value="light"
                    input-id="outlined_input"
                    @change="() => changeColorScheme('light')"
                  ></RadioButton>
                  <label for="outlined_input">Light</label>
                </div>
                <div class="field-radiobutton flex-2">
                  <RadioButton
                    v-model="colorScheme"
                    name="colorScheme"
                    value="dim"
                    input-id="filled_input"
                    @change="() => changeColorScheme('dim')"
                  ></RadioButton>
                  <label for="filled_input">Dim</label>
                </div>
                <div class="field-radiobutton flex-1">
                  <RadioButton
                    v-model="colorScheme"
                    name="colorScheme"
                    value="dark"
                    input-id="filled_input"
                    @change="() => changeColorScheme('dark')"
                  ></RadioButton>
                  <label for="filled_input">Dark</label>
                </div>
              </div>
            </div>
          </a>
        </li>

        <li @click="onLogout">
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
          >
            <span>
              <i class="pi pi-power-off text-xl text-primary"></i>
            </span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">Sign Out</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </Sidebar>
</template>
