import { useScriptTag } from '@vueuse/core';
import { App } from 'vue';
import { ResourceConfig } from '@repo/core/resources';

export class SetupPlugin {
  install(app: App) {
    const router = app.config.globalProperties.$router;
    router.isReady().then(async () => {
      // script tag
      const { load } = useScriptTag(
        `${ResourceConfig.serverBase}enums`,
        () => {
          console.log('enums loaded successfully');
        },
        { manual: true }
      );
      // load enums
      try {
        await load();
      } catch (e: any) {
        console.error('failed to load enums');
      }
    });
  }
}
